<template>
	<div class="main__box" v-if="data">
		<div class>
			<div>
				<h2 class="main__title">{{ data.title }}</h2>
			</div>

			<FilterBox :data="filters"></FilterBox>
		</div>

		<template v-if="filteredList.length > 0">
			<ul class="grid">
				<li
					class="grid__item"
					v-for="(item, index) in filteredList"
					:key="index"
				>
					<router-link
						:to="{ name: data.link, params: { id: item.id } }"
						class="grid__link"
					>
						<div class="grid__logo-box">
							<img :src="item.banner ? item.banner : require('@/assets/images/pictures/stocks_no_banner.png')" :alt="item.name" class="grid__logo" />
						</div>
						<div class="grid__content">
							<template v-if="item.acceptorInfo">
								<h3 class="grid__title">{{ item.acceptorInfo.name }}</h3>
								<p class="grid__text">
									<b>{{ item.name }}</b>
								</p>
							</template>
							<template v-else>
								<h3 class="grid__title">{{ item.name }}</h3>
							</template>
							<p class="grid__text">{{ item.descriptionShort }}</p>
						</div>
					</router-link>
				</li>
			</ul>
			<div class="flex flex--center grid__button-more" v-if="pageCount > page">
				<button class="button" @click="loadMore">Показать еще</button>
			</div>
		</template>
		<template v-else>
			<p class="main__text main__text--center">
				По условиям сортировки ничего не найдено
			</p>
		</template>
	</div>
</template>

<script>
import FilterBox from '@/components/FilterBox.vue'

export default {
	name: 'Stocks',

	props: {
		data: {
			type: Object,
		},
	},

	components: {
		FilterBox,
	},

	data() {
		return {
			page: 1,
			pageSize: 12,
		}
	},

	methods: {
		filteredCity(data) {
			data.list.forEach(item => {
				item.cities.filter(city => {
					if (city == data.pickedCity.id) {
						data.newList.push(item)
					}
				})
			})
		},

		loadMore() {
			this.page++
		},
	},

	computed: {
		pageCount() {
			let l = this.data.list.length,
				s = this.pageSize

			return Math.ceil(l / s)
		},

		filters() {
			return {
				list: true,
				store: {
					dispatch: this.data.filterDispatch,
					getters: this.data.filterGetters,
				},
				filters: [
					{
						name: 'Категория',
						data: [
							{
								type: 'checkbox',
								all: this.data.filterAll,
								list: this.$store.getters.LIST_FILTERS,
							},
						],
					},
				],
			}
		},

		filteredList() {
			const filters = this.$store.getters[this.data.filterGetters]
			const pickedCity = this.$store.getters.PICKEDCITY
			const filterCity = this.$store.getters.APPLIED_CITY
			const paginationEnd = this.page * this.pageSize

			let list = this.data.list
			let newList = []

			if (this.data.removeItem && list) {
				list = list.filter(item => item.id != this.data.removeItem)
			}

			if (filters.all) {
				if (filterCity == 'all') {
					newList = list
				} else {
					this.filteredCity({ list, pickedCity, newList })
				}
			} else {
				filters.list.map(filterType => {
					if (list) {
						list.filter(item => {
							if (item.category === filterType) {
								if (filterCity == 'all') {
									newList.push(item)
								} else {
									item.cities.forEach(city => {
										if (city == pickedCity.id) {
											newList.push(item)
										}
									})
								}
							}
						})
					}
				})
			}
			
			if (newList) {
				return newList.slice(0, paginationEnd)
			} else {
				return []
			}
		},
	},

	mounted() {
		this.$store.dispatch('GET_LIST_FILTERS')
	},
}
</script>

<template>
	<main class="main">
		<section class="container" v-if="item">
			<Crumbs :data="crumbs"></Crumbs>
			<div class="main__box">
				<div class="partner">
					<div class="grid__link grid__link--no-hover">
						<div class="grid__logo-box">
							<img
								:src="item.banner || item.logo"
								:alt="item.name"
								class="grid__logo"
							/>
						</div>
						<div class="grid__content-box">
							<div class="grid__content">
								<h3 class="grid__title">{{ item.name }}</h3>
								<p class="grid__text" v-if="item.descriptionShort">
									{{ item.descriptionShort }}
								</p>
								<a :style="p.isPhone ? '': 'cursor:auto;'" :href="p.isPhone ? 'tel:' + p.value : false" class="link" v-for="p in item.phone" v-bind:key="p.value">
									<span class="icon icon--phone" v-if="p.isPhone"></span>
									{{ toNormalPhone(p.value) }}
								</a>
							</div>
							<div class="grid__button-box">
								<a :class="item.webSiteURL ? '' : 'button-disabled'" :href="item.webSiteURL ? item.webSiteURL : 'javascript:void(0);'" :target="item.webSiteURL ? '_blank' : '_self'" class="button"
									>Веб-сайт</a
								>
							</div>
						</div>
					</div>
					<div class="partner__content">
						<ul class="partner__list">
							<PartnerCollapseItem
								:data="item"
								v-for="(item, index) in loyaltyDescription"
								:key="index"
							></PartnerCollapseItem>
						</ul>

						<Map
							v-show="mapMarkers && mapMarkers.length > 0"
							:data="{
								icon: require('@/assets/images/icons/map_type-2.svg'),
								groupedMarkers: mapMarkers,
							}"
						></Map>
					</div>
				</div>
			</div>
		</section>
		<section class="container">
			<Stocks class="stocks" :data="stocks" v-if="stocks && stocks.list.length > 0"></Stocks>
		</section>
		<section class="container">
			<PartnersBonus
				:data="listLoyaltyPrograms"
				v-if="listLoyaltyPrograms.list"
			></PartnersBonus>
		</section>
	</main>
</template>

<script>
import Crumbs from '@/components/Crumbs.vue'
import PartnersBonus from '@/components/PartnersBonus.vue'
import Stocks from '@/components/Stocks.vue'
import PartnerCollapseItem from '@/components/PartnerCollapseItem.vue'
import Map from '@/components/Map.vue'
import phone from '@/utils/phone'
export default {
	name: 'Partners',

	components: {
		Crumbs,
		PartnersBonus,
		Stocks,
		PartnerCollapseItem,
		Map,
	},

	props: ['id'],

	data() {
		return {
			partner: null,
			partners: null,
		}
	},
    methods: {
		toNormalPhone(p){
			return phone.toNormalPhone(p)
		}
	}, 
	computed: {
		item() {
			const list = this.$store.getters.LIST_ACCEPTORS
			let partner = null
			this.$store.dispatch('GET_ACCEPTOR', this.id)

			if (list) {
				partner = list.filter(item => parseInt(item.id) == parseInt(this.id))
				document.title = partner[0].name
				partner[0].phone = phone.splitPhone(partner[0].phone)
				return partner[0]
			}
			return partner
		},

		mapMarkers() {
			return this.$store.getters.ACCEPTOR ? [{
				organization: { id: this.id },
				children: this.$store.getters.ACCEPTOR.filter(m => m?.geolocation)
			}] : []
		},

		loyaltyDescription() {
			const data = this.item
			const description = new Array()

			if (data.descriptionFull) {
				description.push({
					title: data.name,
					text: data.descriptionFull,
				})
			}

			if (data.loyaltyProgramDescription) {
				description.push({
					title: 'Программа лояльности ',
					text: data.loyaltyProgramDescription,
				})
			}

			return description
		},

		listLoyaltyPrograms() {
			return {
				title: 'Партнеры программы лояльности',
				link: 'PartnerProgram',
				filterDispatch: 'SET_LIST_APPLIED_ACCEPTOR_FILTERS',
				filterGetters: 'LIST_APPLIED_ACCEPTOR_FILTERS',
				filterAll: true,
				removeItem: this.id,
				list: this.$store.getters.LIST_ACCEPTORS,
			}
		},

		crumbs() {
			const data = this.item

			return [
				{
					link: 'Partners',
					params: { alias: 'partner-program' },
					text: 'Партнеры и акции',
				},
				{
					link: null,
					text: data.name,
				},
			]
		},
		stocks() {
			const stocksList = this.$store.getters.LIST_LOYALTY_PROGRAMS
			const acceptors = this.$store.getters.LIST_ACCEPTORS

			let self = this
			let data = stocksList

			if (stocksList && acceptors) {
				data = []
				stocksList.forEach(stock => {
					acceptors.forEach(acceptor => {
						if (
							stock.acceptorId == acceptor.id &&
							stock.acceptorId == self.id
						) {
							stock.acceptorInfo = acceptor
							data.push(stock)
						}
					})
				})
			}
            let st = null
			if (stocksList) {
				st = {
					title: 'Акции',
					link: 'StocksItem',
					filterDispatch: 'SET_LIST_APPLIED_STOCKS_FILTERS',
					filterGetters: 'LIST_APPLIED_STOCKS_FILTERS',
					filterAll: true,
					list: data,
				}
			} else {
				st = null
			}
			return st
		},
	},

	mounted() {
		this.$store.dispatch('GET_LIST_ACCEPTORS')
		this.$store.dispatch('GET_LIST_LOYALTY_PROGRAMS')
		this.$store.dispatch('GET_ACCEPTOR', this.id)
	},
}
</script>
